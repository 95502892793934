<script>
export default {
  data() {
    return {
      configuration: JSON.parse(localStorage.getItem("configuration")),
    };
  },
};
</script>
<template>
  <footer class="footer-peserta">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          Copyright ©
          {{ new Date().getFullYear() }}
          PLN Corpu - UPS. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>

<style scoped>
.footer-peserta {
  bottom: 0;
  padding: 20px calc(24px / 2);
  /*position: absolute;*/
  color: #74788d;
  width: 100%;
  height: 60px;
  background-color: #f2f2f5;
}
</style>
